//$pageloader-opacity: 0.3 !default;


//Font family
body {
  font-family: 'Microsoft JhengHei', 微軟正黑體, 'Helvetica Neue', Helvetica, Arial, STHeitiTC-light, STHeiti, sans-serif;
}


//language Button
$lang_btn_color: #fff;
$lang_btn_bk_color: #E76438;
$lang_btn_bk_color_hover: #CF5025;


//title color
$title_color_black: #0F2B38;
$content_color_black: #666666;

//Owner button
$owner_btn_color: #fff;
$owner_btn_bk_color: #849D46;
$owner_btn_bk_color_hover: #849D46;
$owner_dark_green_color: #5B8029;
$owner_dark_green_color_hover: #476825;


//Owner button2
$owner_btn_color2: #fff;
$owner_btn_bk_color2: #fff;
$owner_btn_bk_color_hover2: #fff;
$owner_btn_bk_color3: #969696;


//Property button
$vendor_btn_color: #fff;
$vendor_btn_bk_color: #E76438;
$vendor_btn_bk_color_hover: #CF5025;


//default color
$bgDefault: #849D46;
$bgHighlight: #849D46;
$colDefault: #fff;
$colHighlight: #e4ffb1;

//product type and location color
$product_type_text: #B89B5E;

.min-vh-50 {
  min-height: 50vh;
  width: 100%;
}

.green_color {
  color: $owner_dark_green_color;
}

.yellow_color{
   color: $vendor_btn_bk_color;
}

.green_bk {
  background-color: $owner_dark_green_color;
  color: #ffffff;
}

.yellow_bk{
   background-color: $vendor_btn_bk_color;
    color: #ffffff;
}

//button shadow
%btn_shadow {
  cursor: pointer;
  //-webkit-box-shadow: 0px 0px 9px 2px #878787;
  //-moz-box-shadow: 0px 0px 9px 2px #878787;
  //box-shadow: 0px 0px 9px 2px #878787;
}


#index_banner .swiper-slide img {
  width: 100%;
}


#index_banner .swiper-pagination {
  position: inherit;
  bottom: 0px;
}


#frontend_custom_list {
  background: #1c7430;
  width: 100%;
  min-height: 50px;
}

.footer_bk_color {
  background-color: #011E2C;
  padding-bottom: 30px;
}


.lang_btn_bk_color {
  background-color: $lang_btn_bk_color;
}

.active_star_color {
  color: $lang_btn_bk_color !important;
}


.file_btn {
  background: #58595b;
  color: #fff;
  height: 30px;
  padding: 5px;
  line-height: 1;

  &:hover {
    background: #78797b;
    color: #fff;
  }
}

.index_hidden_square {
  width: 45%;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 90px solid transparent;
  border-top: 500px solid #859D47;
  position: absolute;
  left: 0px;
  top: 0px;
}

.logo_position {
  position: relative;
  z-index: 1;
}

.banner_image {
  background-color: #cccccc; /* Used if the image is unavailable */

  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding-top: 30px;
  //min-height: 500px;
  @include media-breakpoint-between(xs, sm) {
    min-height: 300px; /* You must set a specified height */
  }
  @include media-breakpoint-between(md, xl) {
    min-height: 500px; /* You must set a specified height */
  }


}


.bd-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: $zindex-modal-backdrop;
  min-height: 3.5rem;
}

.bd-content {
  min-height: calc(100vh - 4rem);
  height: 3000px
}

// stylelint-disable declaration-no-important

//
// Right side table of contents
//

.bd-toc {
  @supports (position: sticky) {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee;

  ul {
    padding-left: 1rem;

    ul {
      display: none;
    }
  }
}

.toc-entry {
  display: block;

  a {
    display: block;
    padding: .125rem 1.5rem;
    color: #99979c;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}

//
// Left side navigation
//

.bd-sidebar {
  order: 0;
  // background-color: #f5f2f9;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.bd-search-docs-toggle {
  line-height: 1;
  color: $gray-900;
}

.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, .65);

  &:hover {
    color: rgba(0, 0, 0, .85);
    text-decoration: none;
  }
}

.bd-toc-item {
  &.active {
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    > .bd-toc-link {
      color: rgba(0, 0, 0, .85);

      &:hover {
        background-color: transparent;
      }
    }

    > .bd-sidenav {
      display: block;
    }
  }
}

// All levels of nav
.bd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, .65);
}

.bd-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, .85);
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: rgba(0, 0, 0, .85);
  background-color: transparent;
}

//button
.lang_btn_layout_base {
  //-webkit-box-shadow: 0px 0px 9px 2px #878787;
  //-moz-box-shadow: 0px 0px 9px 2px #878787;
  //box-shadow: 0px 0px 9px 2px #878787;
  border-radius: 15px;
  color: #fff;
  background-color: $lang_btn_bk_color;
  border-color: $lang_btn_bk_color;
  position: relative;
  z-index: 1;


  select {
    background: none;
    border: none;
    color: #fff;

    &:focus {
      color: #000;
    }
  }
}

.lang_btn_layout {
  @extend .lang_btn_layout_base;

  top: 40px;
  margin-left: 20px;
}

.lang_btn_layout2 {
  color: #fff;
  background-color: $lang_btn_bk_color;
  width: 100%;

  select {
    width: 100%;
    padding: 10px;
  }
}

.lang_btn {
  .btn-secondary {
    @extend %btn_shadow;
    margin: 5px;
    border-radius: 20px;
    border-color: $lang_btn_bk_color;
    background-color: $lang_btn_bk_color;
  }

  .dropdown-item {
    line-height: normal;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: $lang_btn_color;
    background-color: $lang_btn_bk_color_hover;
  }

  &.show .dropdown-toggle {
    color: #fff;
    background-color: $lang_btn_bk_color_hover;
    border-color: $lang_btn_bk_color_hover;
  }

  .dropdown-menu.show {
    @extend %btn_shadow;
    border: 0px;
  }
}

.reply_vendor_row {
  background-color: $lang_btn_bk_color_hover;
  color: #fff;
}

.search_btn_sort{
    width: 100px;
}

.search_btn{
    width: 70px;
}

.search_btn, .search_btn_sort {
  @extend %btn_shadow;
  height: 35px;
  float: right;
  margin: 5px;
  padding: 5px;
  color: $owner_btn_color;
  border-radius: 20px;
  border-color: $owner_dark_green_color;
  background-color: $owner_dark_green_color;
  line-height: 1.3;

  &:hover {
    color: $owner_btn_color;
    background-color: $owner_dark_green_color_hover;
    border-color: $owner_dark_green_color_hover;
  }
}

.owner_btn {
  @extend %btn_shadow;
  height: 35px;
  float: right;
  margin: 5px;
  color: $owner_btn_color;
  border-radius: 20px;
  border-color: $owner_dark_green_color;
  background-color: $owner_dark_green_color;
  line-height: 1.3;

  &:hover {
    color: $owner_btn_color;
    background-color: $owner_dark_green_color_hover;
    border-color: $owner_dark_green_color_hover;
  }
}


.vendor_btn {
  @extend %btn_shadow;
  height: 35px;
  float: right;
  margin: 5px;
  padding: 4px 19px;
  color: $vendor_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_bk_color;
  background-color: $vendor_btn_bk_color;

  &:hover {
    color: $vendor_btn_color;
    background-color: $vendor_btn_bk_color_hover;
    border-color: $vendor_btn_bk_color_hover;
  }
}

.vendor_contract_btn {
  @extend %btn_shadow;
  height: 26px;
  float: right;
  margin: 5px;
  padding: 0px 7px;
  color: $vendor_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_bk_color;
  background-color: $vendor_btn_bk_color;

  &:hover {
    color: $vendor_btn_color;
    background-color: $vendor_btn_bk_color_hover;
    border-color: $vendor_btn_bk_color_hover;
  }
}


.vendor_btn_no_shadow {
  height: 35px;
  margin: 5px;
  padding: 6px 19px;
  color: $vendor_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_bk_color;
  background-color: $vendor_btn_bk_color;

  &:hover {
    color: $vendor_btn_color;
    background-color: $vendor_btn_bk_color_hover;
    border-color: $vendor_btn_bk_color_hover;
  }
}

.vendor_btn_no_shadow_right {
  @extend .vendor_btn_no_shadow;
  float: right;
}

.vendor_btn_register {
  @extend .vendor_btn_no_shadow;
  border-color: $vendor_btn_color;
}


.register_bk {
  background-color: $vendor_btn_bk_color;
  color: #fff;
}

.border-bottom {
  border-bottom: 1px solid #b5b5b5 !important;
}

.more_btn {
  height: 45px;
  line-height: 30px;
  float: right;
  margin: 5px;
  color: $vendor_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_color;
  border-width: 2px;

  &:hover {
    color: #000;
    background-color: $vendor_btn_color;
    border-color: $vendor_btn_color;
  }
}

.owner_btn_bk_color3 {
  background-color: $owner_btn_bk_color3;
}

.more_btn2 {
  height: 45px;
  line-height: 30px;
  float: right;
  margin: 5px;
  color: $owner_btn_bk_color2;
  border-radius: 20px;
  border-color: $owner_btn_bk_color_hover2;
  border-width: 2px;

  &:hover {
    color: $owner_btn_bk_color3;
    background-color: $owner_btn_bk_color2;
    border-color: $owner_btn_bk_color2;
  }
}

#apply_form_li {
  position: relative;
  padding-left: 45px;
  list-style: none;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 15%;
    left: 15px;
    width: 10px;
    height: 67%;
    border-left: 2px solid $owner_dark_green_color;
  }

  li {
    position: relative;
    counter-increment: list;
    padding: 10px;

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      top: 0px;
      height: 100%;
      width: 10px;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 45%;
      left: -35px;
      width: 12px;
      height: 12px;
      border: 2px solid $owner_dark_green_color;
      border-radius: 50%;
      background-color: $owner_dark_green_color;
    }
  }
}

.menu_ul {
  background: #fff;
  width: 100%;
  text-align: center;
  color: $owner_dark_green_color;
  border: 1px $owner_dark_green_color solid;
  padding-inline-start: 0px;
  list-style-type: none;
  margin-bottom: 0px;

  li {
    border-bottom: 1px $owner_dark_green_color solid;
  }

  a {
    color: $owner_dark_green_color;
    width: 100%;
    display: block;
    padding: 15px;
  }
}

.owner_mobile_btn {
  background-color: $owner_dark_green_color;
  color: #ffffff !important;
}

.vendor_mobile_btn {
  background-color: $vendor_btn_bk_color;
  color: #ffffff !important;
}

#apply_form_li2 {
  @extend #apply_form_li;

  &::before {
    border-left: 2px solid $owner_btn_bk_color2;
  }

  li {
    &::after {
      border: 2px solid $owner_btn_bk_color2;
      background-color: $owner_btn_bk_color2;
    }
  }
}

.apply_form_title {
  color: $owner_dark_green_color !important;
  font-weight: bold;
  //text-shadow: 0px 0px 2px $owner_dark_green_color;
  padding: 10px 0px;
  line-height: normal;
}

.slogan {
  color: #fff;
  position: absolute;
  z-index: 1;
  top: 220px;
  line-height: normal;
}

.slogan_mobile {
  color: #fff;
  line-height: normal;
  text-align: left;
  padding: 10px;
}

.card {
  border-radius: 30px;
  border: 0px;
}

.card_img_hover {
  background: black;
  opacity: 0.3;
  border-radius: 6px;

  &:hover {
    opacity: 0 !important;
  }
}

.img_radius {
  border-radius: 6px;
}

.card_img_hover2 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.card_img_hover3 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  opacity: 0.7;
}


.login_btn {
  height: 35px;
  margin: 5px;
  color: $owner_btn_color;
  //padding: 6px 19px;
  border-radius: 20px;
  border-color: $owner_btn_bk_color;
  background-color: $owner_btn_bk_color;
  line-height: 1.3;

  &:hover {
    color: $owner_btn_color;
    background-color: $owner_btn_bk_color_hover;
    border-color: $owner_btn_bk_color_hover;
  }
}

.login_btn_vendor {
  height: 35px;
  margin: 5px;
  padding: 6px 19px;
  color: $vendor_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_bk_color;
  background-color: $vendor_btn_bk_color;

  &:hover {
    color: $vendor_btn_color;
    background-color: $vendor_btn_bk_color_hover;
    border-color: $vendor_btn_bk_color_hover;
  }
}

.footer_font_size {
  font-size: 15px;
}


.login_layout {
  @include media-breakpoint-between(md, xl) {
    width: 100%;
    margin: auto;
  }
  @include media-breakpoint-between(xs, sm) {
    width: 100%;
  }
}

.login_layout2 {
  @include media-breakpoint-between(md, xl) {
    width: 460px;
    margin: auto;
  }
  @include media-breakpoint-between(xs, sm) {
    width: 100%;
  }
}


#quotation_form .form-group {
  margin-bottom: 5px;
}

.forget_password {
  color: $owner_btn_color;

  &:hover {
    color: $owner_btn_color !important;
  }
}


.send_btn {
  height: 35px;
  float: right;
  margin: 5px;
  color: $owner_btn_color;
  border-radius: 20px;
  border-color: $owner_btn_bk_color;
  background-color: $owner_btn_bk_color;

  &:hover {
    color: $owner_btn_color;
    background-color: $owner_btn_bk_color_hover;
    border-color: $owner_btn_bk_color_hover;
  }
}

.search_section {
  //@include media-breakpoint-between(xs, sm) {
  //  position: absolute;
  //  top: 200px;
  //}
  @include media-breakpoint-between(xs, xl) {
    background: $owner_btn_bk_color;
    text-align: center;
  }

}

#scrollTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: $owner_btn_bk_color;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}


.search_div {
  color: #fff !important;
  font-weight: bold;
  //text-shadow: 0px 0px 2px #ffffff;
  padding: 10px;
  line-height: normal;

  @include media-breakpoint-between(xs, sm) {
    &.mt-max {
      margin-top: 10px;
      text-align: center;
    }
  }
  @include media-breakpoint-between(lg, xl) {
    &.mt-max {
      margin-top: 100px;
      margin-left: 100px;
    }
  }


  .search_title {
    margin-bottom: 8px;
  }

  .search_btn {
    margin-left: 10px !important;
    border-radius: 20px !important;
  }


  .search_btn_group {
    line-height: normal;
    background: white;
    border-radius: 20px;
    @include media-breakpoint-between(md, xl) {
      padding-left: 15px;
    }
    @include media-breakpoint-between(xs, sm) {
      padding-left: 15px;
      padding-right: 15px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    select {
      @include media-breakpoint-between(md, xl) {
        width: 130px;
        height: 45px;
      }
      @include media-breakpoint-between(xs, sm) {
        width: 100%;
        height: 45px;
      }
      border: none;
    }
  }

  .search_btn_group_quot {
    line-height: normal;
    background: white;
    border-radius: 20px;
    @include media-breakpoint-between(md, xl) {
      padding-left: 15px;
    }
    @include media-breakpoint-between(xs, sm) {
      padding-left: 15px;
      padding-right: 15px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    select {
      @include media-breakpoint-between(md, xl) {
        width: 130px;
      }
      @include media-breakpoint-between(xs, sm) {
        width: 100%;
      }
      border: none;
    }
  }

  .btn-secondary {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: $vendor_btn_color;
    background-color: $vendor_btn_bk_color;
    border-color: $vendor_btn_bk_color;
  }
}

.menu_item {
  line-height: initial;
  @include media-breakpoint-only(lg) {
    width: 76%;
    float: right;
    line-height: 30px;
  }
  @include media-breakpoint-between(lg, sm) {

  }
}


.menu_text {
  color: #fff !important;
  font-weight: bold;
  //text-shadow: 0px 3px 5px #000000;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: $vendor_btn_bk_color !important;
    font-weight: bold;
    //text-shadow: 0px 3px 3px $vendor_btn_bk_color;
  }
}

.star {
  color: #a9a9a9;

  &.checked {
    color: #efb200 !important;
  }
}


.icon_circle {
  font-size: 5rem;
  border: 5px #fff solid;
  border-radius: 50%;
  display: inline-block;
  width: 150px;
  height: 150px;
  line-height: 150px;
}


.navbar-default {
  background-color: $bgDefault;
  border-color: $bgHighlight;

  .navbar-brand {
    color: $colDefault;

    &:hover, &:focus {
      color: $colHighlight;
    }
  }

  .navbar-text {
    color: $colDefault;
  }

  .navbar-nav {
    > li {
      > a {
        color: $colDefault;

        &:hover, &:focus {
          color: $colHighlight;
        }
      }
    }

    > .active {
      > a, > a:hover, > a:focus {
        color: $colHighlight;
        background-color: $bgHighlight;
      }
    }

    > .open {
      > a, > a:hover, > a:focus {
        color: $colHighlight;
        background-color: $bgHighlight;
      }
    }
  }

  .navbar-toggle {
    border-color: $bgHighlight;

    &:hover, &:focus {
      background-color: $bgHighlight;
    }

    .icon-bar {
      background-color: $colDefault;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: $colDefault;
  }

  .navbar-link {
    color: $colDefault;

    &:hover {
      color: $colHighlight;
    }
  }
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu {
    > li > a {
      color: $colDefault;

      &:hover, &:focus {
        color: $colHighlight;
      }
    }

    > .active {
      > a, > a:hover, > a:focus {
        color: $colHighlight;
        background-color: $bgHighlight;
      }
    }
  }
}

.bg-green2 {
  background-color: $bgDefault;
}


.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $bgDefault;
  border-color: $bgDefault;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $bgDefault;
  background-color: #fff;
  border: 1px solid #dee2e6;

  &:hover {
    z-index: 2;
    color: $bgDefault;
    text-decoration: none;
    background-color: $colHighlight;
  }
}

.product_type_text {
  color: $product_type_text;
}


@media (min-width: 768px) {
  .bd-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    z-index: 1000;
    height: calc(100vh - 5rem);
  }
  .bk_height {
    height: 50vh;
  }
}


.product_icon {
  width: 5rem;
  height: 5rem;
  border-radius: 50% !important;
}


@media (max-width: 767px) {
  .main_banner_custom {

  }
  .bk_height {
    height: 70vh;
  }
}


.main_banner_custom {
  @include media-breakpoint-only(xl) {
    display: flex !important;
  }
  @include media-breakpoint-only(md) {
    display: block !important;
  }
  @include media-breakpoint-between(xs, sm) {
    display: block !important;
    .search_btn_group {
      display: block;
      border-radius: 0px;
      padding: 10px;
      //padding-left: 0px;
      background: none;

      .btn-group {
        width: 100%;
        padding-left: 0px;
        margin-bottom: 10px;

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }
}


.custom-modal-content {
  background-color: #fff0;
  border: none;

  .swiper-pagination-bullet {
    opacity: 1;
    background: #3e501e;
  }
}


.custom_photo_preview {
  &.active {
    display: block;
  }

  display: none;
  height: 100vh !important;
  position: fixed !important;
  left: 0px;
  top: 0px;
  z-index: 1041 !important;

  .close_btn {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    width: 40px;
    height: 40px;
    z-index: 1040;
    border-radius: 10px;
    margin: 5px;
  }

}


.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}


.custom-search {
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-bottom: 20px;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  left: 50%;
}

.main_title_color {
  color: #fff !important;
  font-weight: bold;
  //text-shadow: 0px 0px 2px #ffffff;
  padding: 10px;
  line-height: normal;
}

.custom_btn {
  &:hover {
    .card_custom_radius {
      box-shadow: 0 1px 8px #a9a9a9 !important;
    }
  }
}

.card_custom_radius {
  border-radius: 6px;

  .card-img-top {
    border-top-left-radius: calc(5px);
    border-top-right-radius: calc(5px);
  }
}

.card_custom_radius2 {
  border-radius: 0px !important;
}

.card_custom_radius2.done{
  opacity: 0.5;
}

.card-body {
  padding-bottom: 10px;
  padding-top: 10px;

  .fa-star {
    color: #929292;

    &.checked {
      color: orange;
    }
  }
}

.add_tran_center {
  font-size: 6rem;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 10px);
  -webkit-transform: translate(-50%, 10px);
}

.navbar-toggler-icon {
  i {
    color: #fff;
    font-size: 2rem;
  }
}

.title_size {
  font-size: 13px;
  color: #5b7f29;
}

.owner_bar_border {
  border-color: $lang_btn_bk_color !important;
}


//vendorerties
.vendor_main_title_color {
  color: $bgDefault !important;
  font-weight: bold;
  //text-shadow: 0px 0px 2px $bgDefault;
  padding: 10px 0px;
  line-height: normal;
}

.vendor_main_title_color_black {
  color: $title_color_black !important;
  font-weight: bold;
  //text-shadow: 0px 0px 2px $title_color_black;
  padding: 10px 0px;
  font-weight: bold;
  line-height: normal;
}

.content_color_black {
  color: $content_color_black;
}

.vendor_item_rent_color {
  color: $vendor_btn_bk_color
}

.vendor_main_title_color2 {
  color: $bgDefault !important;
  //font-weight: bold;
  padding: 10px 0px;
  line-height: normal;
}

.vendor_main_title_color3 {
  color: $vendor_btn_bk_color_hover !important;
  //font-weight: bold;
  padding: 10px 0px;
  line-height: normal;
}

.nounderline {
  text-decoration: none !important
}

.body_bk_color_white {
  background-color: #ffffff;
  line-height: 1.4;
}

.body_bk_color_dark_white {
  background-color: #E6E6E7;
}

.vendor_bk_color {
  background-color: #E6E6E7;
}

.vendor_right_box {
  padding: 10px 0px;
}

.vendor_right_border {
  border-bottom: 2px #BFBFBF solid;
}


@keyframes rotate_icon_down {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(180deg);
  }
}

.questionlist_poor {
  .collapsed {
    .open_icon {
      animation-name: rotate_icon_down;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }

  a {
    color: #545454;
  }
}

//vendor page
.vendor_title_bk {
  background-color: $vendor_btn_bk_color;
  color: #fff;
}

.vendor_price_bk {
  background-color: #58595B;
  color: #fff;
}


//member page
.member_title_bk {
  background-color: $owner_btn_bk_color;
  color: #fff;
}

.member_price_bk {
  background-color: #58595B;
  color: #fff;

  &.active {
    background-color: $vendor_btn_bk_color;
  }
}


.change_btn {
  height: 35px;
  margin: 5px;
  color: $owner_btn_color;
  border-radius: 20px;
  border-color: $vendor_btn_bk_color;
  background-color: $vendor_btn_bk_color;
  line-height: 1.3;

  &:hover {
    color: $owner_btn_color;
    background-color: $vendor_btn_bk_color;
    border-color: $vendor_btn_bk_color_hover;
  }
}

.detail_btn {
  height: 35px;
  margin: 5px;
  color: $owner_btn_color;
  border-radius: 20px;
  border-color: $owner_btn_bk_color;
  background-color: $owner_btn_bk_color;

  &:hover {
    color: $owner_btn_color;
    background-color: $owner_dark_green_color_hover;
    border-color: $owner_dark_green_color_hover;
  }
}

.member_left_card {
  @include media-breakpoint-between(lg, xl) {
    //width: 14rem;
    //position: -webkit-sticky;
    //position: sticky;
    //top: 80px;
    //align-self: flex-start;
  }
  @include media-breakpoint-only(md) {
    width: 14rem;
    //position: -webkit-sticky;
    //position: sticky;
    //top: 80px;
    //align-self: flex-start;
  }
  @include media-breakpoint-between(xs, sm) {
    width: 100%;
  }
}

a {
  &.custom_a {
    color: #000000;
  }

  &.add_vendors, .badge_number {
    color: $vendor_btn_bk_color;
  }
}

.custom_tab_btn {
  background: none;
  border-radius: 0px !important;
  color: #000;

  &:hover {
    color: #000;
  }

  &.active {
    background-color: $lang_btn_bk_color_hover !important;
  }
}

.price_title {
  color: #000000;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;

  .col-4 {
    &.active {
      color: #fff;
      background: $vendor_btn_bk_color;
    }

    &:hover {
      color: #fff !important;
      background: $vendor_btn_bk_color;
      cursor: pointer;
    }
  }
}

.badge_custom {
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  display: inline-block;
  position: relative;
  top: -1px;
  border-radius: 10px;
}


//chat room

.chat_box {
  height: 400px;
  overflow-y: scroll;
  border: 1px #bbbbbb solid;
}

.chat_bar {
  background-color: #58595B;
  color: #fff;

}

.chat_send_msg {
  height: 100px;
}

.chat_input {
  height: 100px;
  border: 0px;
  width: 100%;
}

.chat_vendor {
  color: $lang_btn_color;
  background-color: $lang_btn_bk_color;
  border-radius: 8px;
}

.chat_icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50% !important;
}

.chat_group_line {
  &.active {
    background-color: #d6d6d6;
  }

  &:hover {
    background-color: #d6d6d6;
  }
}

#loading_payment {
  display: none;

  &.active {
    display: block;
  }
}

.unselection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#canvas360viewer {
  width: 100%;
  height: 25vh;
}

.submit_button {
  background: $vendor_btn_bk_color;
  color: #ffffff;
  cursor: pointer;
  //&:hover{
  //  background-color:$vendor_btn_bk_color;
  //  color: #ffffff;
  //}
}

body.react-confirm-alert-body-element {
  overflow: auto !important;
}

.react-confirm-alert-button-group {
  justify-content: space-between !important;
}

#change_lang_form1 select {
  background: #e76438;
  border-radius: 20px;
  border: 0px;
  color: #fff;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}

.vendor_create {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border: 3px #5b7f29 solid;
  }

  .invalid-feedback {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: #849c46;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e76438;
  }
}

.min_height {
  min-height: 100px;
}

.contract_layout {
  background-color: #ffffff;
}

.width_100 {
  width: 100px;
}

.checkbox_btn {
  text-align: center;
  border-radius: 3px;
  padding: 3px;
  background-color: #b5b5b5;
  color: #ffffff;

  &.active {
    background-color: #5b7f29;
  }
}

.disable_select_text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#chat_list {
  overflow-y: scroll;
  overflow-x: visible;
  @include media-breakpoint-between(xs, sm) {
    height: 200px;
  }
  @include media-breakpoint-between(md, xl) {
    height: 570px;
  }
}

#property_add{
  .errorlist{
    position: absolute;
    margin-left:  90px;
    list-style-type: none;
    color: red;
  }
}

.vendor_href{
  color: #000;
  :hover{
    color: #000;
  }
}

//#chat_room .col-md-4:first-child {
//  @include media-breakpoint-between(xs, sm) {
//    position: absolute;
//    z-index: 3;
//    left: 0;
//  }
//  //
//  //@include media-breakpoint-up(xl) {
//  //  flex: 0 1 320px;
//  //}
//}

//#canvas360viewer {
//  position: fixed;
//  top: 0;
//  left: 0;
//  z-index: 1040;
//
//  canvas {
//    width: 100vh;
//  }
//}